'use client'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { getStore } from 'common/store'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import { Project } from 'common/project'
import ProfileContainer from 'components/ProfileContainer'

const stripePromise = loadStripe(Project.stripe.key)

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

export default function RootProviders({
  children,
}: {
  children: React.ReactNode
}) {
  const store = getStore()

  useEffect(() => {
    require('bootstrap/dist/js/bootstrap.bundle.min.js')
  }, [])

  return (
    <Provider store={store}>
      <Elements
        stripe={stripePromise}
        options={{
          appearance: {
            theme: 'night',
            variables: {
              colorBackground: '#262A34',
            },
          },
          currency: 'gbp',
          mode: 'setup',
          payment_method_types: ['card'],
        }}
      >
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <ProfileContainer>{children}</ProfileContainer>
        </ThemeProvider>
      </Elements>
    </Provider>
  )
}
