'use client'
import mixpanel from 'mixpanel-browser'
import { FC, useEffect, useState } from 'react'

import { API } from 'project/api'
import { DEV } from 'common/constants'
import { Project } from 'common/project'
import { useGetUserQuery } from 'common/services/useUser'
import { useHydrated } from 'common/services/useHydrated'
import { usePathname } from 'next/navigation'

type ProfileContainerType = {
  children: React.ReactNode | ((data: any) => React.ReactNode)
}

mixpanel.init(Project.mixpanel, {
  debug: DEV ? true : false,
  persistence: 'localStorage',
  track_pageview: false,
})
const ProfileContainer: FC<ProfileContainerType> = ({ children }) => {
  const { data } = useGetUserQuery({})
  const hydrated = useHydrated()
  const [isClient, setIsClient] = useState(false)
  const pathname = usePathname()

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    mixpanel.track_pageview()
  }, [pathname])

  useEffect(() => {
    if (data?.data) {
      API.identifyUserMixpanel(data.data)
    }
  }, [data?.data?.id])

  if (!hydrated || !isClient) return null
  return <>{typeof children === 'function' ? children(data) : children}</>
}

export default ProfileContainer
