'use client'
import dayjs from 'dayjs'
import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useSelectedLayoutSegment } from 'next/navigation'

import { Project } from 'common/project'
import JustfixLogo from './svgs/JustfixLogo'
import { useUser } from 'common/services/useUser'
import DownloadOnApple from './svgs/DownloadOnApple'
import DownloadOnGoogle from './svgs/DownloadOnGoogle'

import {
  AndroidIcon,
  AppStoreIcon,
  FacebookIcon,
  InstagramIcon,
  XIcon,
  YoutubeIcon,
} from './svgs/brands'
import { useGetPropertyAddressQuery } from 'common/services/usePropertyAddress'

const companyLinks = [
  {
    name: 'Investors',
    url: Project.investorsUrl,
  },
  {
    name: 'Recent News',
    url: Project.recentNewsUrl,
  },
  {
    name: 'Climate Positive',
    url: Project.climatePositiveUrl,
  },
]
const aboutLinks = [
  {
    name: 'Privacy',
    url: Project.privacyPolicy,
  },
  {
    name: 'Terms & Conditions',
    url: Project.termsAndConditions,
  },
  {
    name: 'Support Policy',
    url: Project.supportPolicy,
  },
  {
    name: 'Cancellation Policy',
    url: Project.cancellationPolicy,
  },
  {
    name: 'Cookie Policy',
    url: Project.cookiePolicy,
  },
]

const Footer: React.FC = () => {
  const segment = useSelectedLayoutSegment()
  const { isLoading } = useUser({})
  const { isLoading: loadingProperties } = useGetPropertyAddressQuery({})

  if (segment === 'auth' || isLoading || loadingProperties) {
    return null
  }
  return (
    <footer className='d-flex flex-column ms-0 me-0 mt-20 mt-md-40'>
      <div className='container footer-banner px-0'>
        <div className='position-absolute w-100 h-100 footer-gradient' />
        <div className='d-none d-md-flex ps-5 pe-6 row'>
          <div className='col pt-10 pb-7 d-flex flex-column'>
            <h3 className='pb-2'>
              The <span className='text-primary'>JustFix App</span> is designed
              to make your life easier and provide solutions to your home.
            </h3>
            <span>
              Download the JustFix app today for a fast, easy and convenient
              service.
            </span>
          </div>
          <div className='col d-none d-lg-flex align-items-end'>
            <Image
              style={{
                maxWidth: '150%',
              }}
              className='footer-screenshots'
              alt={'justfix-screenshots'}
              src={require('public/images/footer-screenshots.svg')}
            />
          </div>

          <div
            style={{
              zIndex: 100,
            }}
            className='col-md gap-5 d-none d-md-flex align-items-end justify-content-end pb-7'
          >
            <Link href={Project.appleUrl} target='_blank'>
              <DownloadOnApple />
            </Link>
            <Link href={Project.googleUrl} target='_blank'>
              <DownloadOnGoogle />
            </Link>
          </div>
        </div>
        <div className='d-flex d-md-none align-items-center flex-column pt-6'>
          <span
            className='fw-bold text-center'
            style={{
              fontSize: 28,
              maxWidth: 300,
            }}
          >
            The <span className='text-primary'>JustFix App</span> is designed to
            make your life easier and provide solutions to your home.
          </span>
          <div
            className='d-flex justify-content-center'
            style={{
              marginTop: -30,
            }}
          >
            <Image
              style={{
                width: '100%',
              }}
              className='footer-screenshots'
              alt={'justfix-screenshots'}
              src={require('public/images/footer-screenshots-mobile.png')}
            />
          </div>
          <div
            className='d-flex d-md-none flex-row align-items-center gap-5 mb-5 justify-content-center'
            style={{
              marginTop: -30,
            }}
          >
            <Link href={Project.appleUrl} target='_blank'>
              <DownloadOnApple />
            </Link>
            <Link href={Project.googleUrl} target='_blank'>
              <DownloadOnGoogle />
            </Link>
          </div>
        </div>
      </div>
      <div className='bg-midnight-md pt-40 pt-md-36'>
        <div className='container'>
          <div className='d-none d-md-flex row pt-4'>
            <div className='col-6 mb-4'>
              <div className='d-flex flex-column'>
                <JustfixLogo height={64} width={120} />
                <span className='pt-4'>
                  128 City Road, London
                  <br /> United Kingdom
                  <br /> EC1V 2NX
                </span>
                <div className='d-flex flex-row gap-6 my-3'>
                  <Link target='_blank' href={Project.facebookUrl}>
                    <FacebookIcon />
                  </Link>
                  <Link target='_blank' href={Project.twitterUrl}>
                    <XIcon />
                  </Link>
                  <Link target='_blank' href={Project.instagramUrl}>
                    <InstagramIcon />
                  </Link>
                  <Link target='_blank' href={Project.youtubeUrl}>
                    <YoutubeIcon />
                  </Link>
                  <Link target='_blank' href={Project.appleUrl}>
                    <AppStoreIcon />
                  </Link>
                  <Link target='_blank' href={Project.googleUrl}>
                    <AndroidIcon />
                  </Link>
                </div>
                <span className='text-xs'>
                  © {dayjs().format('YYYY')} JustFix Ltd | All rights reserved
                  | Gas Safe No. 937034
                </span>
              </div>
            </div>
            <div className='col mb-4'>
              <h5 className='fw-medium'>Policy</h5>
              <ul className='list-unstyled'>
                {aboutLinks.map((item) => (
                  <li className='py-2' key={item.url}>
                    <a href={item.url}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='col mb-4'>
              <h5 className='fw-medium'>Company</h5>
              <ul className='list-unstyled'>
                {companyLinks.map((item) => (
                  <li className='py-2' key={item.url}>
                    <a href={item.url}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='col'>
              <h5 className='fw-medium'>Apps</h5>
              <Link
                className='d-block py-2'
                href={Project.appleUrl}
                target='_blank'
              >
                IOS
              </Link>
              <Link
                className='d-block py-2'
                href={Project.googleUrl}
                target='_blank'
              >
                Android
              </Link>
            </div>
          </div>
          <div className='d-block d-md-none'>
            <div className='d-flex justify-content-center'>
              <JustfixLogo width={162} height={86} />
            </div>
            <div className='col mt-20'>
              <h5 className='fw-medium'>Policy</h5>
              <ul className='list-unstyled'>
                {aboutLinks.map((item) => (
                  <li className='py-2' key={item.url}>
                    <a href={item.url}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <span className='pt-4'>
              128 City Road, London, United Kingdom
              <br /> EC1V 2NX
            </span>

            <span className='text-xs d-block mt-2 pb-10'>
              © {dayjs().format('YYYY')} JustFix Ltd | All rights reserved |
              Gas Safe No. 937034
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
