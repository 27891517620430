import cn from 'classnames'
import Link from 'next/link'
import { useSelectedLayoutSegment } from 'next/navigation'

const ActiveLink = ({
  className,
  close,
  item,
  ...rest
}: {
  item: { label: string; slug: string }
  className?: string
  close?: () => false | void
}) => {
  const segment = useSelectedLayoutSegment()
  const isActive =
    item.slug === '' && segment === null ? true : item.slug === segment

  return (
    <Link
      onClick={close}
      href={`/${item.slug}`}
      className={cn(
        'nav-link cursor-pointer',
        {
          'active': isActive,
        },
        className,
      )}
      {...rest}
    >
      {item.label}
    </Link>
  )
}

export default ActiveLink
