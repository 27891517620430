'use client'
import { usePathname } from 'next/navigation'
import Script from 'next/script'
import { Suspense, useEffect } from 'react'

import { pageview } from 'components/utils/GoogleTagManager'
import { Project } from 'common/project'

export default function Analytics() {
  const pathname = usePathname()

  useEffect(() => {
    if (pathname) {
      pageview(pathname)
    }
  }, [pathname])

  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    return null
  }

  return (
    <Suspense>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${Project.gtmId}`}
          height='0'
          width='0'
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <Script
        id='gtm-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${Project.gtmId}');
  `,
        }}
      />
    </Suspense>
  )
}
