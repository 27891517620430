'use client'
import cx from 'classnames'
import Link from 'next/link'
import { FC, useState } from 'react'
import { useRouter, useSelectedLayoutSegment } from 'next/navigation'

import { API } from 'project/api'
import UserIcon from './svgs/User'
import Button from './base/forms/Button'
import ActiveLink from './base/ActiveLink'
import JustFixLogo from './svgs/JustfixLogo'
import { BarsIcon, JustfixAppLogo } from './svgs'
import { useLogoutMutation, useUser } from 'common/services/useUser'
import { useGetPropertyAddressQuery } from 'common/services/usePropertyAddress'

type ComponentType = {}

const servicesGroup = {
  label: 'Services',
  links: [
    {
      label: 'Locksmith',
      slug: 'services/locksmith',
    },
    {
      label: 'Electrician',
      slug: 'services/electrical',
    },
    {
      label: 'Gas Engineer',
      slug: 'services/heating_and_gas',
    },
    {
      label: 'Boiler Service',
      slug: 'services/boiler_service',
    },
    {
      label: 'Plumber',
      slug: 'services/plumbing',
    },
    {
      label: 'HandyPerson',
      slug: 'services/handyman',
    },
    {
      label: 'Carpenter',
      slug: 'services/carpentry',
    },
    {
      label: 'Drainage',
      slug: 'services/drains',
    },
    {
      label: 'Roofing',
      slug: 'services/roofing',
    },
    {
      label: 'Glazing',
      slug: 'services/glazing',
    },
    {
      label: 'Whitegoods Repair',
      slug: 'services/white_goods',
    },
    {
      label: 'Something Else',
      slug: 'services/something_else',
    },
  ],
  slug: 'services',
}

const Nav: FC<ComponentType> = () => {
  const router = useRouter()
  const { data: user } = useUser({})
  const [logout] = useLogoutMutation()
  useGetPropertyAddressQuery({})
  const segment = useSelectedLayoutSegment()

  const [isActive, setIsActive] = useState<boolean>(false)

  const loggedInNavLinks = [
    {
      label: 'Home',
      slug: '',
    },
    {
      group: servicesGroup,
    },
    {
      label: 'About Us',
      slug: 'about-us',
    },
  ]

  const navLinks = user?.data?.id
    ? loggedInNavLinks
    : [
        {
          group: servicesGroup,
        },
        {
          label: 'About Us',
          slug: 'about-us',
        },
      ]

  if (segment === 'auth') {
    return null
  }

  const onLogout = async () => {
    API.resetUserMixpanel()
    await logout({})
    setIsActive(false)
    router.push('/auth/login')
  }

  const onNavButtonClicked = (button: string) => {
    API.trackEventMixpanel(`${button} clicked in top nav`, {
      'from': 'browser',
    })
  }

  return (
    <nav
      className='navbar navbar-expand-md navbar-dark bg-navbar'
      style={{
        boxShadow: ` 0px 4px 14px 0px rgba(0, 0, 0, 0.25)`,
      }}
    >
      <div className='container'>
        <button
          onClick={() => setIsActive((prev) => !prev)}
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarCollapse'
          aria-controls='navbarCollapse'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <BarsIcon />
        </button>

        <Link className='navbar-brand me-10 me-lg-20' href='/'>
          <JustFixLogo height={44} width={84} className='d-none d-md-block' />
          <JustfixAppLogo
            height={34}
            width={34}
            className='d-block d-md-none'
          />
        </Link>
        <div className='d-block d-md-none' />

        <div
          className={cx({ collapse: !isActive }, 'navbar-collapse')}
          id='navbarCollapse'
        >
          <ul className='navbar-nav me-auto mb-2 mb-md-0'>
            {navLinks.map((item, index) => {
              if (item.group) {
                return (
                  <li
                    className='nav-item px-2 px-lg-5 dropdown'
                    key={item.group.slug}
                  >
                    <ActiveLink
                      className='nav-link dropdown-toggle'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                      item={item.group}
                    ></ActiveLink>
                    <ul className='dropdown-menu'>
                      {item.group.links.map((groupItem) => (
                        <li key={groupItem.slug}>
                          <ActiveLink
                            item={{
                              label: groupItem.label,
                              slug: groupItem.slug,
                            }}
                            close={() => setIsActive(false)}
                            className='dropdown-item'
                          />
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              }
              return (
                <li key={index} className='nav-item px-2 px-lg-5'>
                  <ActiveLink item={item} close={() => setIsActive(false)} />
                </li>
              )
            })}
          </ul>
          <div className='d-md-flex d-none justify-items-center align-items-center gap-1'>
            <>
              <ul className='navbar-nav me-auto mb-2 mb-md-0'>
                <li className='nav-item px-2 px-lg-5'>
                  <ActiveLink
                    item={{
                      label: 'My Bookings',
                      slug: user?.data?.id ? 'bookings' : 'auth/login',
                    }}
                    close={() => {
                      setIsActive(false)
                      onNavButtonClicked('My Bookings')
                    }}
                  />
                </li>
              </ul>
              <Link href={'/booking/select-service'}>
                <Button
                  theme={'primary'}
                  onClick={() => onNavButtonClicked('Book Now')}
                >
                  Book Now
                </Button>
              </Link>

              {user?.data?.id ? (
                <div className='d-flex'>
                  <div className='nav-item px-2 px-lg-5 dropdown'>
                    <div
                      className='nav-link dropdown-toggle'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <UserIcon />
                    </div>

                    <ul className='dropdown-menu'>
                      <>
                        <li>
                          <Link href='/account' className='dropdown-item'>
                            View Profile
                          </Link>
                        </li>
                        <li>
                          <button onClick={onLogout} className='dropdown-item'>
                            Logout
                          </button>
                        </li>
                      </>
                    </ul>
                  </div>
                </div>
              ) : null}
            </>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
